.font-16 {
  font-size: 16px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-10 {
  font-size: 10px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

// Type display classes
.display-5 {
  font-size: $display5-size;
  font-weight: $display5-weight;
  line-height: $display-line-height;
}

.display-6 {
  font-size: $display6-size;
  font-weight: $display6-weight;
  line-height: $display-line-height;
}

.display-7 {
  font-size: $display7-size;
  font-weight: $display7-weight;
  line-height: $display-line-height;
}

.blockquote {
  border-left: 5px solid $primary;
  border: 1px solid $border-color;
  padding: 15px;
}


.collapseFilter {
  color: #0B57D0;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.font-weight-subtitle {
  font-weight: 600 !important;
  font-size: calc(10px + 0.5vw);
  color: #6C757D !important;
}

.span-new {
  color: #1496EE;
  background-color: #CEF1FF;
  padding: 4px 7px;
  border-radius: 2px;
  font-weight: 700;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #4d4f5c;
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 9999px;
}

*::-webkit-scrollbar:horizontal {
  height: 12px;
}

*::-webkit-scrollbar-thumb:horizontal {
  background-color: #4d4f5c;
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 9999px;
}
.react-datepicker__view-calendar-icon input{
padding: 6px !important
}

@media screen and (min-width: 1920px) {
  .font-weight-subtitle {
    font-size: 20px;
  }
  
}